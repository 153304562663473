exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-american-with-disabilities-act-accessibility-spanish-tsx": () => import("./../../../src/pages/american-with-disabilities-act-accessibility-spanish.tsx" /* webpackChunkName: "component---src-pages-american-with-disabilities-act-accessibility-spanish-tsx" */),
  "component---src-pages-american-with-disabilities-act-accessibility-tsx": () => import("./../../../src/pages/american-with-disabilities-act-accessibility.tsx" /* webpackChunkName: "component---src-pages-american-with-disabilities-act-accessibility-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-or-news-template-tsx": () => import("./../../../src/pages/BlogOrNewsTemplate.tsx" /* webpackChunkName: "component---src-pages-blog-or-news-template-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-event-page-template-tsx": () => import("./../../../src/pages/EventPageTemplate.tsx" /* webpackChunkName: "component---src-pages-event-page-template-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-template-tsx": () => import("./../../../src/pages/LandingPageTemplate.tsx" /* webpackChunkName: "component---src-pages-landing-page-template-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-payments-solutions-b-2-b-payments-tsx": () => import("./../../../src/pages/payments-solutions/b2b-payments.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-b-2-b-payments-tsx" */),
  "component---src-pages-payments-solutions-cryptocurrency-tsx": () => import("./../../../src/pages/payments-solutions/cryptocurrency.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-cryptocurrency-tsx" */),
  "component---src-pages-payments-solutions-digital-banking-platform-tsx": () => import("./../../../src/pages/payments-solutions/digital-banking-platform.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-digital-banking-platform-tsx" */),
  "component---src-pages-payments-solutions-embedded-finance-tsx": () => import("./../../../src/pages/payments-solutions/embedded-finance.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-embedded-finance-tsx" */),
  "component---src-pages-payments-solutions-growth-and-activation-tsx": () => import("./../../../src/pages/payments-solutions/growth-and-activation.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-growth-and-activation-tsx" */),
  "component---src-pages-payments-solutions-investing-and-cash-management-tsx": () => import("./../../../src/pages/payments-solutions/investing-and-cash-management.tsx" /* webpackChunkName: "component---src-pages-payments-solutions-investing-and-cash-management-tsx" */),
  "component---src-pages-platform-banking-core-tsx": () => import("./../../../src/pages/platform/banking-core.tsx" /* webpackChunkName: "component---src-pages-platform-banking-core-tsx" */),
  "component---src-pages-platform-cloud-infrastructure-tsx": () => import("./../../../src/pages/platform/cloud-infrastructure.tsx" /* webpackChunkName: "component---src-pages-platform-cloud-infrastructure-tsx" */),
  "component---src-pages-platform-galileo-open-apis-tsx": () => import("./../../../src/pages/platform/galileo-open-apis.tsx" /* webpackChunkName: "component---src-pages-platform-galileo-open-apis-tsx" */),
  "component---src-pages-platform-trusted-partners-tsx": () => import("./../../../src/pages/platform/trusted-partners.tsx" /* webpackChunkName: "component---src-pages-platform-trusted-partners-tsx" */),
  "component---src-pages-privacy-disclosure-spanish-tsx": () => import("./../../../src/pages/privacy-disclosure-spanish.tsx" /* webpackChunkName: "component---src-pages-privacy-disclosure-spanish-tsx" */),
  "component---src-pages-privacy-disclosure-tsx": () => import("./../../../src/pages/privacy-disclosure.tsx" /* webpackChunkName: "component---src-pages-privacy-disclosure-tsx" */),
  "component---src-pages-products-card-issuing-tsx": () => import("./../../../src/pages/products/card-issuing.tsx" /* webpackChunkName: "component---src-pages-products-card-issuing-tsx" */),
  "component---src-pages-products-credit-and-lending-tsx": () => import("./../../../src/pages/products/credit-and-lending.tsx" /* webpackChunkName: "component---src-pages-products-credit-and-lending-tsx" */),
  "component---src-pages-products-data-and-analytics-tsx": () => import("./../../../src/pages/products/data-and-analytics.tsx" /* webpackChunkName: "component---src-pages-products-data-and-analytics-tsx" */),
  "component---src-pages-products-deposits-and-banking-tsx": () => import("./../../../src/pages/products/deposits-and-banking.tsx" /* webpackChunkName: "component---src-pages-products-deposits-and-banking-tsx" */),
  "component---src-pages-products-payment-processing-and-money-transfers-tsx": () => import("./../../../src/pages/products/payment-processing-and-money-transfers.tsx" /* webpackChunkName: "component---src-pages-products-payment-processing-and-money-transfers-tsx" */),
  "component---src-pages-products-risk-and-compliance-tsx": () => import("./../../../src/pages/products/risk-and-compliance.tsx" /* webpackChunkName: "component---src-pages-products-risk-and-compliance-tsx" */),
  "component---src-pages-services-customer-service-and-ivr-tsx": () => import("./../../../src/pages/services/customer-service-and-ivr.tsx" /* webpackChunkName: "component---src-pages-services-customer-service-and-ivr-tsx" */),
  "component---src-pages-services-dispute-operations-tsx": () => import("./../../../src/pages/services/dispute-operations.tsx" /* webpackChunkName: "component---src-pages-services-dispute-operations-tsx" */),
  "component---src-pages-services-fraud-operations-tsx": () => import("./../../../src/pages/services/fraud-operations.tsx" /* webpackChunkName: "component---src-pages-services-fraud-operations-tsx" */),
  "component---src-pages-services-program-management-tsx": () => import("./../../../src/pages/services/program-management.tsx" /* webpackChunkName: "component---src-pages-services-program-management-tsx" */),
  "component---src-pages-sms-policy-tsx": () => import("./../../../src/pages/sms-policy.tsx" /* webpackChunkName: "component---src-pages-sms-policy-tsx" */)
}

